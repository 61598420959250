.features {
    background-color: #F8F8F8;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.features-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 4rem;
    text-align: center;
}

.features-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem;
}

.feature-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow: hidden;
}

.feature-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 1rem;
}

.feature-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.feature-description {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
}
