/* Style the header */
.App-header {
    background-color: #F8F8F8;
    color: #333;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Style the logo */
.App-logo {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
}

/* Style the navigation */
nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

nav li {
    margin: 0 10px;
}

nav a {
    color: #333;
    text-decoration: none;
    font-weight: bold;
}