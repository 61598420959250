body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
}

.App {
  text-align: center;
}

.App-landing-page {
  background-color: #F8F8F8;
  min-height: 100vh; /* Set the minimum height to the height of the viewport */
  display: flex;
  flex-direction: column;
}
