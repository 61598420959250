.map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%; /* Set the width of the container to 100% */
    height: auto; /* Let the height adjust based on the content */
}

@media screen and (max-width: 768px) {
    .map-container {
        width: 90%; /* Decrease the width on smaller screens */
        margin: 0 auto; /* Center the container */
    }
}